<template>
    <div class="d-flex" style="height: 100%;">
        <div class="leftSections">
          <v-list dense class="listLeft">
              <v-list-item v-for="(container, index) in orderItems" :key="index" :color="container.id == selectedContainer.id ? 'edit' : ''" @click="selectedContainer = container" dense class="listItemLeft ml-0">
                  <v-list-item-avatar>
                    <v-icon v-if="!container.totalPallets && container.allocatedPallets" color="danger">error_outline</v-icon>
                    <v-progress-circular v-else-if="container.totalPallets && container.allocatedPallets" :value="container.allocatedProgress" size="20"
                      :color=" container.totalPallets == 0 ? 'grey' : container.allocatedProgress < 20 ? 'red' : container.allocatedProgress < 50 ? 'orange' : container.allocatedProgress < 100 ? 'blue' : 'success' " />
                  </v-list-item-avatar>
                  <v-list-item-content>
                      <v-list-item-title>
                          {{container.containerNo}}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                          {{container.ctoNo}}
                      </v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
          </v-list>
        </div>
        <div class="rightSection">
          <div class="actionRow">
            <v-chip small dense outlined class="mr-2 px-2" @click="changeDisplayAllocated()"><v-icon class="chipIcon">filter_list</v-icon>Display: {{displayAllocated ? 'Allocated' : 'All'}}</v-chip>
            <div v-if="!displayAllocated">
              <v-chip small dense outlined class="mr-2 pl-2" :class="selectedSSCC ? 'pr-1' : 'pr-2'" @click="autoAllocate()" :disabled="selectedSSCC == selectedContainer.totalPallets" v-if="!noMoreAllocation"><v-icon class="chipIcon">auto_awesome</v-icon>Auto Allocate (FIFO) <v-chip class="px-1 py-0 mr-0 ml-1" x-small color="tertiary" v-if="selectedSSCC < selectedContainer.totalPallets">{{ selectedContainer.totalPallets - selectedSSCC }}</v-chip></v-chip>
              <v-chip small dense outlined class="mr-2 pl-2" :class="selectedSSCC ? 'pr-1' : 'pr-2'" @click="processAllocation()" :disabled="selectedSSCC == 0" v-if="selectedSSCC != 0 && !noMoreAllocation"><v-icon class="chipIcon">save</v-icon>Save Allocation <v-chip class="px-1 py-0 mr-0 ml-1" x-small color="tertiary" v-if="selectedSSCC">{{selectedSSCC}}</v-chip></v-chip>
              <v-chip small dense outlined class="mr-2 px-2" :disabled="selectedSSCC == 0" @click="removeAllocation()" v-if="selectedSSCC != 0 && !noMoreAllocation"><v-icon class="chipIcon">close</v-icon>Clear Allocation</v-chip>
              <v-chip small dense :outlined="!allowDualLoad" :color="allowDualLoad ? 'tertiary' : ''" class="mr-2 px-2" @click="changeDualLoadStatus()" v-if="!noMoreAllocation"><v-icon class="chipIcon">local_shipping</v-icon>Allow Dual Load</v-chip>
            </div>
            <v-spacer></v-spacer>
            <v-chip small dense outlined class="mr-2 pl-2"><v-icon class="chipIcon">today</v-icon>Average Age<v-chip class="px-1 py-0 mr-0 ml-1" x-small color="teal" >{{averageAge ?? 0}}</v-chip></v-chip>
            <v-chip small dense outlined class="mr-2 pl-2"><v-icon class="chipIcon">tag</v-icon>Total Pallets<v-chip class="px-1 py-0 mr-0 ml-1" x-small color="blue-grey" >{{calculatePallets ?? 0}}</v-chip></v-chip>
            <v-chip small dense outlined class="mr-2 pl-2"><v-icon class="chipIcon">check</v-icon>Allocated<v-chip class="px-1 py-0 mr-0 ml-1" x-small color="tertiary" >{{calculateAllocatedPallets ?? 0}}</v-chip></v-chip>
            <v-chip small dense outlined class="mr-2 pl-2"><v-icon class="chipIcon">warning</v-icon>Unallocated<v-chip class="px-1 py-0 mr-0 ml-1" x-small color="warning" >{{calculateUnallocatedPallets ?? 0}}</v-chip></v-chip>
          </div>
          <DynamicTable @updateSelectedPallets="selectPallet" :headers="headers" :data="displayData" :groupCategories="groupCategories" type="stock" :selectedPallets="selectedPallets" :loading="loading" :customHeaders="false" :heightMultiplier="0.85" :simpleFilters="true"/>
        </div>
        <div :class="['sideDrawer', { show: sideDrawer }]">
          <v-card style="width: 100%; height: 100%; background: var(--v-greyDarker-base) !important;" tile flat>
            <v-list dense subheader>
              <v-subheader style="font-size: 14px">
                Load Outs <v-spacer></v-spacer>
                <v-btn color="primary" @click="createLoadOutInstruction()" :loading="savingLoadOut" small icon >
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
              <v-list-item v-for="instruction in selectedContainer.loadOutInstructions" :key="instruction.id">
                <v-list-item-avatar>
                  <v-btn plain small icon @click="downloadDocument(instruction)"><v-icon>download</v-icon></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ instruction.locationCode }} - V{{ instruction.version }}
                    ({{ instruction.pallets }} Pallets)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <li v-if="!selectedContainer.loadOutInstructions || selectedContainer.loadOutInstructions.length == 0 " style="color: grey; padding: 10px 10px;" >
                  No Load Out Instructions
                </li>
              <v-subheader style="font-size: 14px">
                Paste SSCC's 
              </v-subheader>
              <v-divider></v-divider>
              <div v-if="pasteErrors" style="width: 100%">
                <span style="font-size: 12px"
                >Error adding SSCC's: {{ pasteErrors }}
              </span>
              </div>
              <div class="pa-2">
                <v-textarea
                placeholder="Paste SSCC's here"
                v-model="pasteData"
                outlined
                rows="6"
                hide-details
                ></v-textarea>
              </div>
              <div class="d-flex align-center justify-center" style="width: 100%;">
                <v-btn small color="primary" :disabled="!pasteData" @click="processPasteData">Accept</v-btn>
              </div>
            </v-list>
          </v-card>
        </div>
    </div>
</template>

<script>
import DynamicTable from "../../AgGrid/DynamicTable.vue";
export default {
    components: {
      DynamicTable,
    },
    props: ['orders', 'sideDrawer'],
    data: () => ({
        selectedContainer: {},
        loading: false,
        loadingItems: false,
        orderItems: [],
        loadedContainers: [],
        customers: [],
        loadOutKey: 1,
        loadingStock: false,
        groupCategories: [
            {
                name: 'Status',
                value: 'status',
                icon: 'verified'
            },
            {
                name: 'Location',
                value: 'locationCode',
                icon: 'pin'
            },
            {
                name: 'Count Size',
                value: 'countSize',
                icon: 'calculate'
            },
            {
                name: 'Age',
                value: 'age',
                icon: 'tag'
            },
            {
                name: 'Mark',
                value: 'mark',
                icon: 'sell'
            },
            {
              name: 'Inventory Code',
              value: 'inventoryCode',
              icon: 'code'
            },
            {
              name: 'Variety',
              value: 'varietyCode',
              icon: 'category'
            }
        ],
        headers: [
        {
          text: "",
          value: "actions",
          width: "30px",
          disableSort: true,
          disableFilter: true,
          align: 'center',
          show: true,
        },
        {
          text: "Location",
          value: "locationCode",
          width: "80px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Age",
          value: "age",
          width: "50px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Status",
          value: "status",
          width: "100px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "SSCC",
          value: "sscc",
          width: "150px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Seq.",
          value: "sequenceNo",
          width: "50px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Comm",
          value: "commodityCode",
          width: "70px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Var",
          value: "varietyCode",
          width: "75px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Class",
          value: "grade",
          width: "50px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Pack",
          value: "packCode",
          width: "75px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Count Size",
          value: "countSize",
          width: "75px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Mark",
          value: "mark",
          width: "75px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: 'Inv Code',
          value: 'inventoryCode',
          width: '75px',
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Cartons",
          value: "cartonQuantity",
          width: "75px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Plt. Qty",
          value: "palletQuantity",
          width: "50px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "PHC",
          value: "phc",
          width: "75px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "PUC",
          value: "puc",
          width: "75px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: "Orch.",
          value: "orchard",
          width: "75px",
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: 'Target Market',
          value: 'targetMarket',
          width: '100px',
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: 'Origin Country',
          value: 'country',
          width: '100px',
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: 'Target Country',
          value: 'targetCountry',
          width: '100px',
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        {
          text: 'Target Region',
          value: 'targetRegion',
          width: '100px',
          align: 'center',
          disableSort: false,
          disableFilter: false,
          show: true,
        },
        ],
        selectedPallets: [],
        pasteData: null,
        pasteErrors: null,
        pasteSSCC: false,
        stock: [],
        filter: {},
        filterKey: 100,
        filteredValues: [],
        displayAllocated: false,
        search: "",
        sortBy: null,
        sortDesc: false,
        displayData: [],
        loadingAutoAllocation: false,
        noMoreAllocation: false,
        allowDualLoad: false,
        loadingAllocated: false,
        orderData: [],
        loadingAllocation: false,
        loadingRemoveAllocation: false,
        selectedPalletKey: 111,
        loadingStatus: false,
        updateStatusModal: false,
        productQuantityTypes: [
          { value: "BG", text: "Bag" },
          { value: "TT", text: "Bag, tote" },
          { value: "BA", text: "Barrel" },
          { value: "BL", text: "Bale" },
          { value: "BI", text: "Bin" },
          { value: "BX", text: "Box" },
          { value: "VL", text: "Bulk, liquid" },
          { value: "VR", text: "Bulk, solid" },
          { value: "BH", text: "Bunch" },
          { value: "BE", text: "Bundle" },
          { value: "CT", text: "Carton" },
          { value: "CS", text: "Case" },
          { value: "CN", text: "Container" },
          { value: "CR", text: "Crate" },
          { value: "DR", text: "Drum" },
          { value: "FB", text: "Flexibag" },
          { value: "JT", text: "Jutebag" },
          { value: "LU", text: "Lug" },
          { value: "PK", text: "Package" },
          { value: "PA", text: "Packet" },
          { value: "PX", text: "Pallet" },
          { value: "PT", text: "Pot" },
          { value: "PO", text: "Pouch" },
          { value: "RO", text: "Roll" },
          { value: "SH", text: "Sachet" },
          { value: "SA", text: "Sack" },
          { value: "SI", text: "Skid" },
          { value: "PU", text: "Tray" },
          { value: "VI", text: "Vial" },
        ],
        savingLoadOut: false,
    }),
    watch: {
        orders: {
            immediate: true,
            handler(val) {
                if (val && val.length > 0) {
                  if ( this.loadedContainers.length == 0 || !this.loadedContainers.every((x) => val.map((y) => y.id).includes(x) )) {
                    this.loadContainers(val.map((x) => x.id));
                    this.loadedContainers = val.map((x) => x.id);
                  }
                }
            },
        },
    },  
    created() {
        
    },
    computed: {
      selectedSSCC() {
      return [
        ...new Set(
          this.stock
            .filter((x) => this.selectedPallets.includes(x.id))
            .map((x) => x.sscc)
        ),
      ].length;
    },
    averageAge() {
      let result =
        this.displayData.map((x) => x.age).reduce((a, b) => a + b, 0) /
        this.displayData.length;
      return Math.round(result * 100) / 100;
    },
    calculateAllocatedPallets() {
      let result = [ ...new Set(this.displayData.filter((x) => x.bookingContainerId))].length;
      return result;
    },
    calculateUnallocatedPallets() {
      let result = [ ...new Set( this.displayData .filter((x) => !x.bookingContainerId).map((x) => x.sscc))].length;
      return result;
    },
    calculatePallets() {
      let result = [...new Set(this.displayData.map((x) => x.sscc))].length;
      return result;
    },
    },
    methods: {
        async loadContainers(orders) {
            this.loading = true;
            this.loadingItems = true;
            this.orderItems = await this.$API.getAllOrderContainersBasic({
              batch: orders,
            });
            this.orderItems.forEach((x) => { x.assignedProgress = (x.assignedStock / x.totalPallets) * 100;});
            let uniqueCustomers = [ ...new Set(this.orders.map((x) => x.orderCustomerId))];
            let data = [];
            uniqueCustomers = uniqueCustomers .map((x) => this.orders.find((y) => y.orderCustomerId == x)).map((x) => x.orderCustomer).filter(Boolean);
            for (let i = 0; i < uniqueCustomers.length; i++) {
              let findCustomer = this.orders.find( (x) => x.orderCustomerId == uniqueCustomers[i].id );
              data.push({
                ...findCustomer.orderCustomer,
                type: "customer",
              });
              let orders = this.orders.filter( (x) => x.orderCustomerId == uniqueCustomers[i].id );
              orders.forEach((x) => {
                let containers = this.orderItems.filter((y) => y.bookingId == x.id);
                data.push({
                  ...x,
                  type: "order",
                });
                containers.forEach((y) => {
                  y.type = "container";
                  y.orderCustomerId = uniqueCustomers[i].id;
                });
                data = [...data, ...containers];
              });
            }
            this.customers = data;
            if (this.orderItems.length > 0) {
              this.setActiveContainer(this.orderItems[0]);
            }
            this.loadingItems = false;
            this.loading = false;
        },
        async setActiveContainer(container) {
            this.selectedContainer = container;
            if(!this.selectedContainer.loadOutInstructions) {
              this.selectedContainer.loadOutInstructions = []
            } else {
              console.log(this.selectedContainer.loadOutInstructions)
            }
            if(this.selectedContainer.allocatedProgress == 100) {
              this.displayAllocated = true
            }
            this.loadOutKey++;
            await this.loadRelatedStock();
        },
        calculateDisplayData() {
            let result = [...this.stock];
            if (!this.displayAllocated) {
              result = result.filter((x) => !x.bookingContainerId);
            } else {
              result = result.filter( (x) => x.bookingContainerId || this.selectedPallets.includes(x.id) );
            }
            if(this.displayAllocated) {
              let allStuffed = true
              result.forEach((x) => {
                console.log(x.status)
                if(x.status != 'Stuffed') {
                  allStuffed = false
                }
              })
              if(allStuffed) {
                this.noMoreAllocation = true
              }
            }
            this.displayData = result;
            this.filterKey++;
        },
        changeDisplayAllocated(){
          this.displayAllocated = !this.displayAllocated;
          this.calculateDisplayData();
        },
        changeDualLoadStatus() {
          this.allowDualLoad = !this.allowDualLoad;
        },
        autoAllocate() {
          this.loadingAutoAllocation = true;
          let remaining = this.selectedContainer.totalPallets - this.selectedSSCC;
          let sliceResult = [...this.displayData];
          let allowedStores = [];
          let uniqueStores = [
            ...new Set(this.displayData.map((x) => x.locationCode)),
          ];
          console.log(uniqueStores);
          let calculatedStores = [];
          for (let i = 0; i < uniqueStores.length; i++) {
            let store = this.displayData.filter(
              (x) => x.locationCode == uniqueStores[i]
            );
            let averageAge = store.map((x) => x.age).reduce((a, b) => a + b, 0) / store.length;
            calculatedStores.push({ id: uniqueStores[i], age: averageAge });
          }
          console.log(calculatedStores);
          calculatedStores.sort((a, b) => { return a.age < b.age ? 1 : -1});
          allowedStores = calculatedStores.length > 1 ? [calculatedStores[0].id, calculatedStores[1].id] : calculatedStores.map((x) => x.id);
          if (this.allowDualLoad) {
            sliceResult = sliceResult.filter((x) => allowedStores.includes(x.locationCode));
          } else {
            sliceResult = sliceResult.filter( (x) => x.locationCode == allowedStores[0]);
          }
          console.log(sliceResult);
          sliceResult.sort((a, b) => {
            return a.age < b.age ? 1 : -1;
          });
          this.selectedPallets = [
            ...this.selectedPallets,
            ...sliceResult.slice(0, remaining).map((x) => x.id),
          ];
          console.log(this.selectedPallets);
          this.loadingAutoAllocation = false;
          this.processAllocation();
        },
        locationCodeCount(code) {
          return this.allocated.filter((x) => x.locationCode == code).length;
        },
        async loadContainerAllocation() {
          this.loadingAllocated = true;
          this.allocated = await this.$API.getContainerStockAllocation(
            this.selectedContainer.id
          );
          this.selectedPallets = this.stock
            .filter((x) => this.allocated.map((y) => y.id).includes(x.id))
            .map((x) => x.id);
          this.loadingAllocated = false;
        },
        async loadOrderStock() {
          let data = await this.$API.getOrderAllocations({
            orders: this.orders.map((x) => x.id),
          });
          this.orderData = data;
        },
        async loadRelatedStock() {
          this.loadingStock = true;
          this.selectedPallets = [];
          console.log(JSON.parse(JSON.stringify(this.selectedContainer)));
          let data = await this.$API.getRelatedStock(this.selectedContainer.id);
          this.stock = data;
          console.log(this.stock);
          this.selectedPallets = this.stock.filter((x) => x.bookingContainerId == this.selectedContainer.id) .map((x) => x.id);
          this.calculateDisplayData();
          this.loadingStock = false;
        },
        selectPallet(item) {
          let findSSCC = this.stock
            .filter((x) => x.sscc == item.sscc)
            .map((x) => x.id);
          if (this.selectedPallets.includes(item.id)) {
            this.selectedPallets = this.selectedPallets.filter(
              (x) => !findSSCC.includes(x)
            );
          } else {
            this.selectedPallets = [...this.selectedPallets, ...findSSCC];
          }
          this.selectedPalletKey++;
        },
        async processAllocation() {
          try {
            this.loadingAllocation = true;
            let filtered = this.stock.filter((x) =>
              this.selectedPallets.includes(x.id)
            );
            let result = await this.$API.allocateOrder(this.selectedContainer.id, {
              stock: this.selectedPallets,
              orderId: this.selectedContainer.bookingId,
            });
            let findIndex = this.orderItems.findIndex(
              (x) => x.id == this.selectedContainer.id
            );
            this.orderItems[findIndex].assignedStock = [
              ...new Set(result.map((x) => x.sscc)),
            ].length;
            this.orderItems[findIndex].assignedProgress = Math.round(
              (this.orderItems[findIndex].assignedStock /
                this.orderItems[findIndex].totalPallets) *
                100
            );
            this.orderItems[findIndex].cartons = result
              .map((x) => x.cartonQuantity)
              .reduce((a, b) => a + b, 0);
            let remove = this.stock.filter(
              (x) =>
                x.bookingContainerId == this.selectedContainer.id &&
                !this.selectedPallets.includes(x.id)
            );
            remove.forEach((x) => {
              x.bookingContainerId = null;
              x.bookingContainer = null;
              x.status = "In Stock";
            });
            filtered.forEach((x) => {
              x.bookingContainerId = this.selectedContainer.id;
              x.bookingContainer = this.selectedContainer;
              x.status = "Allocated";
            });
            this.loadingAllocation = false;
            this.$message.success("Successfully allocated pallets!");
          } catch (e) {
            console.log(e);
            this.$message.error("An error ocurred!");
            this.loadingAllocation = false;
          }
        },
        processPasteData() {
          let data = this.pasteData.split("\n");
          let pasteErrors = [];
          let count = 0;
          for (let i = 0; i < data.length; i++) {
            let sscc = data[i].trim();
            let pallet = this.stock.filter((x) => x.sscc == sscc);
            if (
              pallet.length > 0 &&
              this.selectedSSCC < (this.selectedContainer.totalPallets || 20) &&
              !this.selectedPallets.includes(pallet.id)
            ) {
              this.selectedPallets = [
                ...pallet.map((x) => x.id),
                ...this.selectedPallets,
              ];
              count += 1;
            } else {
              pasteErrors.push(sscc);
            }
          }
          this.pasteErrors = pasteErrors.join(", ");
          this.$message.success(count + " pallets added!");
        },
        async removeAllocation() {
          this.loadingRemoveAllocation = true;
          await this.updatePalletStatus("In Stock");
          this.selectedPallets = [];
          await this.processAllocation();
          this.loadingRemoveAllocation = false;
        },
        async updatePalletStatus(status) {
          try {
            this.loadingStatus = true;
            await this.$API.updateStockPalletStatus(this.selectedPallets, status);
            this.selectedContainer.status = status;
            let filtered = this.stock.filter((x) =>
              this.selectedPallets.includes(x.id)
            );
            filtered.forEach((x) => {
              x.status = status;
            });
            this.loadingStatus = false;
            this.updateStatusModal = false;
            this.$message.success("Successfully updated pallet status!");
          } catch (e) {
            console.log(e);
            this.loadingStatus = false;
          }
        },
        productSpec(item) {
          let keys = [
            { key: "productId", value: "Product" },
            { key: "varietyProductId", value: "Variety" },
            { key: "puc", value: "PUC" },
            { key: "phc", value: "PHC" },
            { key: "packCode", value: "PACK" },
            { key: "grade", value: "Grade" },
            { key: "countSize", value: "Count" },
            { key: "inventoryCode", value: "Inventory Code" },
          ];
          let result = [];
          for (let i = 0; i < keys.length; i++) {
            if (keys[i].key == "productId" && item.product) {
              result.push(item.product.name);
            } else if (keys[i].key == "varietyProductId" && item.varietyProduct) {
              result.push(item.varietyProduct.name);
            } else if (item[keys[i].key]) {
              result.push(keys[i].value + ": " + item[keys[i].key]);
            }
          }
          return result.join(" - ");
        },
        async createLoadOutInstruction() {
          try {
            this.savingLoadOut = true;
            let result = await this.$API.createLoadOutInstructions(
              this.selectedContainer.id
            );
            this.selectedContainer.loadOutInstructions = result ?? []
            let findIndex = this.orderItems.findIndex(
              (x) => x.id == this.selectedContainer.id
            );
            this.orderItems[findIndex].loadOutInstructions = result;
            this.$message.success("Successfully generated load out instruction!");
            this.loadOutKey++;
            this.savingLoadOut = false;
          } catch (e) {
            console.log(e);
            this.savingLoadOut = false;
          }
        },
        
        async downloadDocument(doc) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href = doc.url;
          hiddenElement.target = "_blank";
          hiddenElement.download = doc.key.split("/").at(-1);
          hiddenElement.click();
        },
        formatBaseUnit(type) {
          let result = this.productQuantityTypes.find((x) => x.value == type);
          return result ? result.text : "";
        },
        async sendLoadOutInstruction() {
          try {
            this.savingLoadOut = true;
            await this.$API.sendLoadOutInstruction(this.selectedContainer.id);
            this.savingLoadOut = false;
          } catch (e) {
            console.log(e);
            this.savingLoadOut = false;
          }
        },

    }
}
</script>

<style scoped>
.leftSections {
    height: 100%;
    width: 10%;
    min-width: 10%;
    max-width: 10%;
    background:  var(--v-greyDarker-base) !important;
}
.rightSection {
    height: 100%;
    width: 100%;
    min-width: 85%;
    max-width: 100%;
    background: var(--v-greyRaised-base) !important;
}
.actionRow {
    display: flex;
    width: 100%;
    height: 36px;
    min-height: 36px;
    max-height: 72px;
    padding: 0 5px;
    align-items: center;
    background-color: var(--v-greyRaised-base) !important;
}
.listItemLeft {
    min-height: 38px;
    height: 38px;
    padding: 0 0px !important;
}
.listLeft {
    background-color: transparent !important;
}
.v-list-item {
    min-height: 38px;
    height: 38px;
    padding: 10px 8px !important;
}
.v-list-item__avatar {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
}
.v-list-item__action {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.v-list-item__title {
    font-size: 0.75rem !important;
    line-height: 1.4 !important;
}

.v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: -17px;
  background: #444444;
  color: #fff;
}
.chipIcon {
    margin-right: 3px;
    font-size: 16px !important;
}
.sideDrawer {
    background-color: var(--v-greyRaised-base) !important;
    height: calc(100% - 36px) !important;
    min-height: calc(100% - 36px) !important;
    width: 18vw;
    box-shadow: -10px 0 10px -5px rgba(0, 0, 0, 0.5);
    min-width: 18vw;
    max-width: 50%;
    z-index: 10;
    right: -18vw;
    top: 62px;
    position: fixed;
    transition: right 0.2s ease-in-out;
    margin-top: -30px !important;
    contain: content;
    display: block;
}

.sideDrawer.show {
    right: 36px;
}
</style>